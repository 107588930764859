import React, {Component} from 'react';
import './App.css';

import Login from './include/pages/login'
import Cookies from 'universal-cookie';


import {
    BrowserRouter as Router,
    Route
} from 'react-router-dom'
import axios from 'axios'

const cookies = new Cookies();


class App extends Component {

    constructor(props) {
        super(props)
        this.state = {module: null};
        this.check = this.check.bind(this)
    };

    async check() {
        const data = cookies.get("appmanager_session");
        if (data !== undefined) {
            import('./include/_config.js')
                .then(module => this.setState({module: module.PrivatePagesRoute}))
            /*
            const response = await axios.post(global.server + 'api/validate', {
                token: data
            })
            if (response.data.status === 200) {
                import('./include/_config.js')
                    .then(module => this.setState({module: module.PrivatePagesRoute}))
            }
            */
        }
    }

    componentDidMount() {
        this.check()
    }

    render() {
        const data = cookies.get("appmanager_session");
        if (data === undefined) {
            return (
                <div className="App">
                    <Router>
                        <div style={{width: '100%'}}>
                            <Route component={Login}/>
                        </div>
                    </Router>

                </div>
            );
        }
        if (this.state.component === null) {
            return null
        }
        const {module: Component} = this.state;
        return (
            <div className="App">


                <Router>
                    <div style={{width: '100%'}}>
                        {Component && <Component/>}
                        <Route exact path='/manage/login' component={Login}/>
                    </div>
                </Router>

            </div>
        );
    }
}

export default App;
