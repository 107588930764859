import React, { useEffect} from 'react';
import axios from 'axios'
import Cookies from 'universal-cookie';
import {GoogleOAuthProvider, GoogleLogin} from '@react-oauth/google';

const cookies = new Cookies();


//332570316499-ni44p3p2c84qc3020id4p83qa5mtf6dm.apps.googleusercontent.com
export default function Login() {
    useEffect(() => {
        const data = cookies.get("appmanager_session");
        if (data === undefined) {
            return;
        }

        console.log("********** login")
        axios.get(global.server + `validate-token?jwt=${data}`)
            .then(function (response) {
                if (response.status === 200) {
                    window.location.href = "/manage";
                } else {

                }
            })
            .catch(function (error) {

            });
    }, [])

    return <div
        style={{width: '100vw', display: 'flex', justifyContent: 'center', alignContent: 'center'}}>
        <GoogleOAuthProvider clientId="332570316499-ni44p3p2c84qc3020id4p83qa5mtf6dm.apps.googleusercontent.com">
            <GoogleLogin
                onSuccess={async credentialResponse => {
                    const email = JSON.parse(atob(credentialResponse.credential.split(".")[1])).email
                    try {
                        axios.post(global.server + 'api/login', {
                            email: email
                        })
                            .then(function (response) {
                                if (response.data.status === 200) {
                                    cookies.set("appmanager_session", response.data.session, {
                                        path: '/',
                                        maxAge: 31536000
                                    });
                                    window.location.href = "/manage/";
                                }
                            })
                            .catch(function (error) {

                            });
                    } catch (err) {
                    }

                }}
                onError={() => {
                    console.log('Login Failed')
                }}
            />

        </GoogleOAuthProvider>


    </div>


}



